import React from 'react'
import LoggedinNavbar from '../Component/LoggedinNavbar'
import { Col, Container, Row } from 'reactstrap'
import BlogDetailsBanner from '../Component/BlogDetailsBanner'
import { Link, useLocation } from 'react-router-dom'
import Blog1 from '../Assets/Images/blogPic1.webp'

export default function BlogDetails() {
  const location = useLocation()
  const { blogDetail, allBlogList } = location.state

  return (
    <>
      <LoggedinNavbar />
      <Container>
        <Row>
          <Col lg={7} md={7} sm={12}>
            <Container className="mt-3 d-flex justify-content-center">
              <BlogDetailsBanner blogImages={blogDetail?.blogImages} />
            </Container>
            <Row className="mt-2 mb-5">
              {blogDetail?.blogTags.map((value) => {
                return (
                  <Col lg={2} md={2} sm={2} xl={2}>
                    <span className="py-1 px-3 mx-1 rounded-5 back-orange text-white">
                      {value?.tag}
                    </span>
                  </Col>
                )
              })}
            </Row>
          </Col>
          <Col lg={5} md={5} sm={12} className="mt-3">
            <div className="mb-3 orange-color text-center">
              <h3>
                <u>Our Recent Blogs</u>
              </h3>
            </div>
            {allBlogList?.length > 0 &&
              allBlogList.map((value, index) => {
                if (index < 2) {
                  return (
                    <Link
                        to="/blogDetails"
                        state={{ blogDetail: value,allBlogList:allBlogList }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                    <Row className="mb-4">
                      <Col lg={5} md={5} sm={12} style={{ overflow: 'hidden' }}>
                      
                        <img
                          src={value?.blogImages?.[0]?.image}
                          alt=""
                          className="img-fluid"
                        />
                      </Col>
                      <Col lg={7} md={7} sm={12}>
                        <p className="mb-1">
                          Created . {value?.createdAt?.split('T')[0]}
                        </p>
                        <h5>{value?.title}</h5>
                        <div className="d-flex">
                          {value?.blogTags?.length > 0 &&
                            value?.blogTags?.map((tabValue, tagIndex) => {
                              if(tagIndex < 3){
                                return (
                                <span className="py-1 px-3 mx-1 rounded-5 back-orange text-white">
                                  {tabValue?.tag}
                                </span>
                              )
                              }
                             
                            })}
                        </div>
                      </Col>
                    </Row>
                    </Link>
                  )
                }
              })}
          </Col>
        </Row>
        <Container>
          <div
            className="blogContent"
            dangerouslySetInnerHTML={{
              __html: blogDetail?.long_description,
            }}
          ></div>
        </Container>
      </Container>
    </>
  )
}
