export const ApiUrl = {
  // server_url: "http://58c7-39-61-51-234.ngrok.io/api/",
  // server_url: "http://192.168.0.119:4001/api/",
  // server_url: "http://localhost:4001/api/",

  // Dev Backend ...
  // server_url:
  //   "http://ec2-3-135-226-148.us-east-2.compute.amazonaws.com:3000/api/",

  // Staging Backend ...
  // server_url:
  //   "http://ec2-3-143-143-125.us-east-2.compute.amazonaws.com:3000/api/",

  // Heroku Staging Backend ...
  // server_url: "https://r8tr-apis.herokuapp.com/api/",

  // Live Backend ...
  server_url: "https://viahop-development-cf9c9.ondigitalocean.app/api/",
  // server_url: "http://192.168.100.157:3000/api/",
};
