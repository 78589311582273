import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default function BlogDetailsBanner(props) {
  const data = props?.blogImages?.map((value) => {
    return value?.image;
  });

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  return (
    <Carousel
      showIndicators={true}
      autoPlay={true}
      infiniteLoop={true}
      showArrows={false}
      interval={3000}
      width={'80%'}
      slideImageFit="cover"
      radius="10px"
      style={{innerHeight: '30rem'}}
    >
      {props?.blogImages?.map((value) => {
        return (
          <div>
            <img src={value?.image} height={200} className="img-fluid" />
          </div>
        );
      })}
    </Carousel>
  );
}
