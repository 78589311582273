import React, { useState, useLayoutEffect, useContext, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap'
import ellipsRow from '../Assets/Images/default.png'
import LanguageContext from '../../Context/LanguageContext'
import user_logo from '../Assets/Images/user_logo.svg'
import nav_flag from '../Assets/Images/nav_flag.png'
import logoo from '../Assets/Images/logoo1.png'
import russianFlag from '../Assets/Images/russianFlag.png'
import logoAndName from '../Assets/Images/logoo1.png'
import NameAndLogoPNG from '../Assets/Images/logoo1.png'

import {
  getUserData,
  completeProfileUser,
} from '../../redux/actions/UserAction'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import SecondNav from './SecondNav'
import { LANGUAGE } from '../../redux/types'
import { getHeroSectionData } from '../../redux/actions/TravellerOfferAction'

export default function LoggedinNavbar(props) {
  const dispatch = useDispatch()
  const token = localStorage.getItem('token')
  const i18nLang = localStorage.getItem('i18nextLng')
  const { t } = useTranslation()

  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const [prevScrollY, setPrevScrollY] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [nav, setNav] = useState()
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18nLang ? i18nLang : 'en',
  )
  const navigate = useNavigate()
  const { userDetails } = useSelector((state) => state.UserReducer)

  // const { language, setlanguage } = useContext(LanguageContext);
  // console.log(language, "asddsadsaasdsdaasddsa");
  const { i18n } = useTranslation()
  const location = useLocation()

  useLayoutEffect(() => {
    dispatch(getUserData())
  }, [])
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const isScrolledUp = currentScrollY < prevScrollY
      console.log(currentScrollY, 'currentScrollY')
      console.log(prevScrollY, 'currentScrollY1')
      setIsScrollingUp(isScrolledUp)
      if (currentScrollY === 0) {
        return setIsScrollingUp(true)
      }
      setIsScrollingUp(isScrolledUp)
      setPrevScrollY(currentScrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollY, location, nav])
  const handleNav = (data) => {
    setNav(data)
  }

  function logout() {
    localStorage.removeItem('token')
    navigate('/')
    dispatch({ type: 'GET_USER_DETAIL', payload: {} })
  }
  function toggle() {
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    if (window.location.search.split('=')[1] === 'ru') {
      i18n.changeLanguage('rs')
    } else if (i18nLang) {
      i18n.changeLanguage(selectedLanguage)
      if (selectedLanguage == 'rs') {
        dispatch({ type: LANGUAGE, payload: 'russian' })
        let data = {
          language: 'russian',
        }
        dispatch(getHeroSectionData(data))
      } else {
        dispatch({ type: LANGUAGE, payload: 'english' })
        let data = {
          language: 'english',
        }
        dispatch(getHeroSectionData(data))
      }
    }
  }, [])

  const handleStartGivingServices = (str) => {
    if (str === 'sender') {
      if (token) {
        if (
          userDetails?.user?.address != null &&
          userDetails?.user?.address != '' &&
          userDetails?.user?.is_phone_verified != 0 &&
          userDetails?.user?.is_id_verified != 0 &&
          userDetails?.user?.is_email_verified != 0
        ) {
          navigate('/create_sender_offer')
        } else {
          Swal.fire({
            title: t('Whoops!'),
            type: 'danger',
            text: t(
              'You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go',
            ),
            footer:
              '<a href="/main_profile?key=second">' +
              t('Link to Verification Page') +
              '</a>',
          })
        }
      } else {
        navigate('/login')
      }
    } else if (str === 'traveller') {
      if (token) {
        if (
          userDetails?.user?.address != null &&
          userDetails?.user?.address != '' &&
          userDetails?.user?.is_phone_verified != 0 &&
          userDetails?.user?.is_id_verified != 0 &&
          userDetails?.user?.is_email_verified != 0
        ) {
          navigate('/create_traveller_offer')
        } else {
          Swal.fire({
            title: t('Whoops!'),
            type: 'danger',
            text: t(
              'You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go',
            ),
            footer:
              '<a href="/main_profile?key=second">' +
              t('Link to Verification Page') +
              '</a>',
          })
        }
      } else {
        navigate('/login')
      }
    }
  }

  return (
    <div
      style={{
        position: isScrollingUp ? 'sticky' : 'relative',
        top: 0,
        transition: ' all 2s ease-in-out',
        background: 'white',
        zIndex: 10,
      }}
      className={`${isScrollingUp ? 'navtransition' : ''}`}
    >
      {' '}
      <div className="container-fluid px-4">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Navbar
              color=""
              expand="lg"
              style={{ background: 'white !important' }}
            >
              <Link to="/" className="navbarLogo">
                {' '}
                <NavbarBrand>
                  <img
                    src={logoAndName}
                    alt="logoo"
                    style={{ width: '100%', minWidth: 100 }}
                  />
                </NavbarBrand>
              </Link>
              <div className="d-flex">
                <UncontrolledDropdown
                  nav
                  inNavbar
                  className="my-3 my-lg-0 list-type d-lg-none d-block me-lg-0 me-3"
                >
                  <DropdownToggle nav caret className="fontSize20-600">
                    <img
                      src={selectedLanguage === 'rs' ? russianFlag : nav_flag}
                      width={20}
                      alt="nav_flag"
                    />{' '}
                    {selectedLanguage === 'rs' ? 'Ru' : 'En'}
                  </DropdownToggle>
                  <DropdownMenu right className="nav-dropoption flag_set">
                    <DropdownItem className="fontSize20-600 d-flex justify-content-around">
                      <p
                        onClick={() => {
                          i18n.changeLanguage('en')
                          setSelectedLanguage('en')
                          dispatch({ type: LANGUAGE, payload: 'english' })
                          let data = {
                            language: 'english',
                          }
                          dispatch(getHeroSectionData(data))
                          window.location.reload()
                        }}
                      >
                        English{' '}
                        <span>
                          {' '}
                          <img
                            src={nav_flag}
                            style={{ marginLeft: '8px' }}
                            alt="nav_flag"
                          />
                        </span>
                      </p>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem className="fontSize20-600 ">
                      <p
                        onClick={() => {
                          i18n.changeLanguage('rs')
                          setSelectedLanguage('rs')
                          dispatch({ type: LANGUAGE, payload: 'russian' })
                          let data = {
                            language: 'russian',
                          }
                          dispatch(getHeroSectionData(data))
                          window.location.reload()
                        }}
                      >
                        Русский{' '}
                        <span>
                          {' '}
                          <img
                            src={russianFlag}
                            className="arabic_flag"
                            alt="nav_flag"
                          />
                        </span>
                      </p>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavbarToggler onClick={toggle} className="bordt-none" />
              </div>

              <Collapse isOpen={isOpen} navbar className="ms-auto ">
                <Nav
                  className="ms-5 navtwo-mrauto via-nav align-items-baseline"
                  navbar
                >
                  {/* <NavItem>
                    <Link to="/explore">
                      <NavLink
                        className="fontSize20-600 navi ms-3"
                        style={{ color: "#222222" }}
                      >
                        {t("Explore Current Offers")}
                      </NavLink>
                    </Link>
                  </NavItem> */}
                  <div className="d-lg-flex">
                    <NavItem>
                      <Link to="/">
                        {' '}
                        <NavLink
                          className="fontSize20-600 navi  ms-3"
                          style={{ color: '#F69110' }}
                        >
                          {t('Home')}
                        </NavLink>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link to="/about">
                        <NavLink
                          className="fontSize20-600 navi ms-3"
                          style={{ color: '#222222' }}
                        >
                          {t('About Us')}
                        </NavLink>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link to="/term">
                        <NavLink
                          className="fontSize20-600 navi ms-3"
                          style={{ color: '#222222' }}
                        >
                          {t('Terms of Use')}
                        </NavLink>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link to="/fq">
                        <NavLink
                          className="fontSize20-600 navi ms-3"
                          style={{ color: '#222222' }}
                        >
                          {t('FAQs')}
                        </NavLink>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link to="/blogs">
                        <NavLink
                          className="fontSize20-600 navi ms-3"
                          style={{ color: '#222222' }}
                        >
                          {t('Blogs')}
                        </NavLink>
                      </Link>
                    </NavItem>
                  </div>
                </Nav>
                {userDetails?.user ? (
                  <UncontrolledDropdown nav inNavbar className="ms-3 list-type">
                    <DropdownToggle nav caret className="UserNameOnNav">
                      {userDetails?.user?.firstName +
                        ' ' +
                        userDetails?.user?.lastName}
                      <img
                        src={user_logo}
                        alt="Profile"
                        className="navlogo-twowidth ms-2"
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      right
                      className="nav-dropoption-two prof_drop"
                    >
                      <Link
                        to="/main_profile"
                        style={{ textDecoration: 0 }}
                        state={{ activeKey: 'first' }}
                      >
                        {' '}
                        <DropdownItem className=" navdropdown-twohov">
                          {t('Profile Settings')}
                        </DropdownItem>
                      </Link>
                      <DropdownItem divider />
                      <Link
                        state={{ activeKey: 'third' }}
                        to="/main_profile?key=third"
                        style={{ textDecoration: 0 }}
                      >
                        <DropdownItem
                          // onClick={logout}
                          className=" navdropdown-twohov"
                        >
                          {t('My Sender Offers')}
                        </DropdownItem>{' '}
                      </Link>
                      <DropdownItem divider />
                      <Link
                        eventKey="third"
                        to="/main_profile?key=fourth"
                        state={{ activeKey: 'fourth' }}
                        style={{ textDecoration: 0 }}
                      >
                        <DropdownItem
                          // onClick={logout}
                          className=" navdropdown-twohov"
                        >
                          {t('My Traveller Offers')}
                        </DropdownItem>{' '}
                      </Link>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={logout}
                        className=" navdropdown-twohov"
                      >
                        {t('Logout')}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : (
                  <NavbarText className="ms-5 my-3 my-lg-0">
                    <Link to="/login">
                      <Button className="orange-background bordt-none signInOrRegisterBtn px-3 me-3">
                        {t('Sign In')}/{t('Register')}
                      </Button>
                    </Link>
                  </NavbarText>
                )}

                <UncontrolledDropdown
                  nav
                  inNavbar
                  className="ms-5 my-3 my-lg-0 list-type d-lg-block d-none"
                >
                  <DropdownToggle nav caret className="fontSize20-600">
                    <img
                      src={selectedLanguage === 'rs' ? russianFlag : nav_flag}
                      width={20}
                      alt="nav_flag"
                    />{' '}
                    {selectedLanguage === 'rs' ? 'Ru' : 'En'}
                  </DropdownToggle>
                  <DropdownMenu right className="nav-dropoption flag_set">
                    <DropdownItem className="fontSize20-600 d-flex justify-content-around">
                      <p
                        onClick={() => {
                          i18n.changeLanguage('en')
                          setSelectedLanguage('en')
                          dispatch({ type: LANGUAGE, payload: 'english' })
                          window.location.reload()
                        }}
                      >
                        English{' '}
                        <span>
                          {' '}
                          <img
                            src={nav_flag}
                            style={{ marginLeft: '8px' }}
                            alt="nav_flag"
                          />
                        </span>
                      </p>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem className="fontSize20-600 ">
                      <p
                        onClick={() => {
                          i18n.changeLanguage('rs')
                          setSelectedLanguage('rs')
                          dispatch({ type: LANGUAGE, payload: 'russian' })
                          window.location.reload()
                        }}
                      >
                        Русский{' '}
                        <span>
                          {' '}
                          <img
                            src={russianFlag}
                            className="arabic_flag"
                            alt="nav_flag"
                          />
                        </span>
                      </p>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Collapse>
            </Navbar>
          </Col>
        </Row>
      </div>
      <SecondNav handleNav={handleNav} isScrolledUp={isScrollingUp} />
    </div>
  )
}
