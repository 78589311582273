import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, NavItem, NavLink } from "reactstrap";
import Swal from "sweetalert2";

const SecondNav = ({ handleNav }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const scrollToLittleBottom = () => {
    window.scrollTo(0, 200);
  };

  const token = localStorage.getItem("token");
  const { userDetails } = useSelector((state) => state.UserReducer);

  const handleStartGivingServices = (str) => {
    handleNav(str);
    // scrollToLittleBottom();
    if (str === "sender") {
      if (token) {
        if (
          userDetails?.user?.address != null &&
          userDetails?.user?.address != "" &&
          userDetails?.user?.is_phone_verified != 0 &&
          userDetails?.user?.is_id_verified != 0 &&
          userDetails?.user?.is_email_verified != 0
        ) {
          navigate("/create_sender_offer");
        } else {
          Swal.fire({
            title: t("Whoops!"),
            type: "danger",
            text: t(
              "You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"
            ),
            footer:
              '<a href="/main_profile?key=second">' +
              t("Link to Verification Page") +
              "</a>",
          });
        }
      } else {
        navigate("/login");
      }
    } else if (str === "traveller") {
      if (token) {
        if (
          userDetails?.user?.address != null &&
          userDetails?.user?.address != "" &&
          userDetails?.user?.is_phone_verified != 0 &&
          userDetails?.user?.is_id_verified != 0 &&
          userDetails?.user?.is_email_verified != 0
        ) {
          navigate("/create_traveller_offer");
        } else {
          Swal.fire({
            title: t("Whoops!"),
            type: "danger",
            text: t(
              "You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"
            ),
            footer:
              '<a href="/main_profile?key=second">' +
              t("Link to Verification Page") +
              "</a>",
          });
        }
      } else {
        navigate("/login");
      }
    }
  };

  return (
    <div style={{ background: "#fff4e6" }}>
      <div
        className="secondNavBtns"
        style={{
          // display: "flex",

          width: "100%",
          // marginLeft: " auto",
          // marginRight: " auto",
          display: "flex",
          flexDirection: "row",
          listStyle: "none",

          // -webkit-overflow-scrolling: touch,
          scrollBehavior: "smooth",
          overflowX: "auto",
          padding: 10,
          paddingLeft: 0,
          // -ms-overflow-style: none,
          scrollbarWidth: "none",
          // position: "absolute",
          // left: "50%",
          // transform: "translateX(50%)",
          // marginRight: 10,
          // marginLeft: 10,
          alignItems: "center",
          // justifyContent: "center",
          // overflowX: "scroll",
          // paddingLeft: 20,
          // paddingRight: 20,
        }}
      >
        <NavItem className="marginexplore-btn">
          <Link to="/explore">
            <NavLink
              className="fontSize20-600 navi ms-1"
              style={{ color: "#222222" }}
            >
              <Button className="back-orange bordt-none">
                {t("Explore Current Offers")}
              </Button>
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          {/* <Link to="/explore"> */}
          <NavLink
            className="fontSize20-600 navi ms-1"
            style={{ color: "#222222" }}
          >
            <Button
              onClick={() => handleStartGivingServices("sender")}
              className="back-orange bordt-none"
            >
              {t("Create Sender Offer")}
            </Button>
          </NavLink>
          {/* </Link> */}
        </NavItem>

        <NavItem>
          {/* <Link to="/explore" */}
          <NavLink
            className="fontSize20-600 navi ms-1"
            style={{ color: "#222222" }}
          >
            <Button
              onClick={() => handleStartGivingServices("traveller")}
              className="back-orange bordt-none"
            >
              {t("Create Traveller Offer")}
            </Button>
          </NavLink>
          {/* </Link> */}
        </NavItem>
      </div>
    </div>
  );
};

export default SecondNav;
